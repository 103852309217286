(function () {
	'use strict';

	angular.module('dcApp').directive('pivotgridview', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'GridViewCommon',
				'$timeout',
				function ($scope, GridViewCommon, $timeout) {
					var vm = this;

					var chartOptions = {
						commonSeriesSettings: {
							type: 'bar',
						},
						tooltip: {
							enabled: true,
							customizeTooltip: function (args) {
								return {
									html: args.seriesName + ' ' + args.valueText,
								};
							},
						},
						size: {
							height: 200,
						},
						adaptiveLayout: {
							width: 450,
						},
						onInitialized: function (e) {
							$scope.chart = e.component;
						},
					};

					var getFields = function (aggregation) {
						let fields = [];
						for (let r in $scope.config.global.rows) {
							let field = getCol($scope.config.global.rows[r]);
							field.area = 'row';
							fields.push(field);
						}
						for (let r in $scope.config.global.columns) {
							let field = getCol($scope.config.global.columns[r]);
							field.area = 'column';
							fields.push(field);
						}
						for (let r in $scope.config.global.values) {
							let field = getCol($scope.config.global.values[r]);
							field.area = 'data';
							field.summaryType = aggregation
								? aggregation.toLowerCase()
								: 'sum';
							fields.push(field);
						}
						return fields;
					};

					var getCol = function (uuid) {
						let col = _.find($scope.group.columns, function (item) {
							return item.uuid == uuid;
						});
						return { caption: col.lib, width: 120, dataField: uuid };
					};

					$scope.showPopover = function ($event, obj) {
						if (Object.keys(obj).length === 0) {
							$scope.noFilterApplied = true;
						} else {
							$scope.noFilterApplied = false;
						}
						// Set a bigger z-index
						$(function () {
							DevExpress.ui.dxOverlay.baseZIndex(1999999998);
						});
					};

					function initPopover() {
						$scope.sourceCentring = vm.data.centering
							? vm.data.centering.source_centering
							: {};
						$scope.filterDetailsPopover = {
							target: '#filterInfoPopover' + $scope.uuid,
							showEvent: 'dxclick',
							position: 'top',
							width: 450,
							bindingOptions: {
								visible: 'visibleMetaData',
							},
						};
					}

					vm.init = function (ignoreCache) {
						function checkPivotConfig(config) {
							return true;
						}
						function loadGroupDataInGrid(data) {
							initPopover();
							let fields = getFields($scope.config.global.aggregation);
							var dataSource = _.map(data, function (item) {
								var newItem = {};
								for (var c in fields) {
									newItem[fields[c].dataField] = item[fields[c].dataField].val;
								}
								return newItem;
							});

							let gridOptions = {
								allowSortingBySummary:
									$scope.config.global.allow_sorting_by_summary,
								allowFiltering: true,
								showBorders: true,
								showColumnGrandTotals:
									$scope.config.global.show_column_grand_totals,
								showRowGrandTotals: $scope.config.global.show_row_grand_totals,
								showRowTotals: $scope.config.global.show_row_totals,
								showColumnTotals: $scope.config.global.show_column_totals,
								fieldChooser: { enabled: true, height: 400 },
								showTotalsPrior: $scope.config.global.show_totals_prior,
								scrolling: { mode: 'virtual' },
								onInitialized: function (e) {
									if ($scope.config.global.chart_visible) {
										e.component.bindChart($scope.chart, {
											dataFieldsDisplayMode: 'splitPanes',
											alternateDataFields: false,
										});
									}
								},
							};

							if ($scope.config.global.field_panel_visible) {
								gridOptions.fieldPanel = {
									visible: true,
									showColumnFields: $scope.config.global.show_column_fields,
									showDataFields: $scope.config.global.show_data_fields,
									showFilterFields: $scope.config.global.show_filter_fields,
									showRowFields: $scope.config.global.show_row_fields,
								};
							}

							GridViewCommon.setCommonGridConfig(gridOptions, $scope.config);
							gridOptions.dataSource = { fields: fields, store: dataSource };
							$scope.pivotGridOptions = gridOptions;
							if ($scope.config.global.chart_visible) {
								$scope.chartOptions = angular.copy(chartOptions);
								$scope.chartOptions.tooltip.enabled =
									$scope.config.global.chart_tooltip;
								$scope.chartOptions.commonSeriesSettings.type = $scope.config
									.global.chart_sub_type
									? $scope.config.global.chart_sub_type
									: $scope.config.global.chart_type;
								if ($scope.config.global.chart_palette) {
									$scope.chartOptions.palette =
										$scope.config.global.chart_palette.replace('_', ' ');
								}
								$scope.chartOptions.legend = {
									visible: $scope.config.global.chart_legend,
									verticalAlignment: 'center',
									horizontalAlignment: 'right',
									position: 'outside',
									orientation: 'vertical',
								};
								if ($scope.config.global.export_panel_enabled) {
									$scope.chartOptions.export = { enabled: true };
								}
								$timeout(function () {
									$('#chart' + $scope.uuid)
										.dxChart('instance')
										.render();
								}, 5);
							}
						}

						GridViewCommon.initializeGridViewScope(
							ignoreCache,
							$scope,
							vm,
							'pivot',
							checkPivotConfig,
							loadGroupDataInGrid
						);
						$scope.init(ignoreCache);

						if (!vm.data.full_data) {
							$scope.jobDetails = { rerunMethod: $scope.rerunWithoutCache };
							$scope.jobDetailsId = generateUuid('_');
						}
					};
				},
			];

			return buildDirectiveReturnObject(
				{ data: '=' },
				controller,
				'./src/components/directives/handledata/gridView/pivot/gridView.html',
				function postLink(scope, element, attrs, $ctrl) {
					const ignoreCache = scope.vm.data.ignoreCache || false;
					$ctrl.init(ignoreCache);
				}
			);
		},
	]);
})();
