(function () {
	'use strict';

	angular.module('dcApp').directive('projectExportHistory', [
		'$parse',
		function ($parse) {
			var controller = [
				'API_BASE_URL_BACKEND',
				'$state',
				'$scope',
				'$rootScope',
				'$q',
				'toaster',
				'PAGINATIONS_SIZE',
				'PAGINATIONS_SIZES',
				'ExportHistoryService',
				'gettextCatalog',
				'ProjectService',
				function (
					API_BASE_URL_BACKEND,
					$state,
					$scope,
					$rootScope,
					$q,
					toaster,
					PAGINATIONS_SIZE,
					PAGINATIONS_SIZES,
					ExportHistoryService,
					gettextCatalog,
					ProjectService
				) {
					const vm = this;

					let dataGirdColumnName = {};
					dataGirdColumnName.filename = gettextCatalog.getString(
						'project.export.history.filename'
					);
					dataGirdColumnName.status = gettextCatalog.getString(
						'project.export.history.status'
					);
					dataGirdColumnName.date = gettextCatalog.getString(
						'project.export.history.date'
					);
					dataGirdColumnName.result = gettextCatalog.getString(
						'project.export.history.result'
					);
					dataGirdColumnName.user = gettextCatalog.getString(
						'project.export.history.user'
					);
					vm.downloadFile = downloadFile;
					vm.hasProjectRole = $rootScope.hasProjectRole;

					let lookupUserDataSource = {
						store: new DevExpress.data.CustomStore({
							loadMode: 'raw',
							load: function () {
								return ProjectService.getMembersByProjectId(
									$rootScope.currentProjectId
								).then(function (data) {
									return data;
								});
							},
						}),
					};

					let historyElements = new DevExpress.data.CustomStore({
						load: function (loadOptions) {
							var parameters = {};
							parameters.skip = loadOptions.skip || 0;
							parameters.take = loadOptions.take || 25;
							var options = { filter: [], sort: {} };

							for (let s in loadOptions.sort) {
								options.sort[loadOptions.sort[s].selector] =
									loadOptions.sort[s].desc;
							}

							createCondition(loadOptions.filter, options.filter, false);

							return ExportHistoryService.getProjectExportHistory(
								$rootScope.currentProjectId,
								parameters.skip,
								parameters.take,
								options
							).then(function (response) {
								return {
									data: getProjectExportHistory(response.data),
									totalCount: Number(response.headers['x-total-count']),
								};
							});
						},
					});

					let dataGridOptions = {
						dataSource: {
							store: historyElements,
						},
						remoteOperations: {
							sorting: true,
							paging: true,
							filtering: true,
						},
						paging: {
							enabled: true,
							pageSize: PAGINATIONS_SIZE,
						},
						scrolling: {
							rowRenderingMode: 'Standard',
							showScrollbar: 'onHover',
						},
						pager: {
							showPageSizeSelector: true,
							allowedPageSizes: PAGINATIONS_SIZES,
							infoText: $rootScope.dxGridTextInfo,
							showInfo: true,
							visible: true,
						},
						searchPanel: {
							visible: true,
							width: 240,
						},
						filterRow: {
							visible: true,
							applyFilter: 'auto',
						},
						rowAlternationEnabled: true,
						headerFilter: {
							visible: true,
							applyFilter: 'auto',
						},
						showColumnLines: true,
						showRowLines: true,
						allowColumnReordering: true,
						allowColumnResizing: true,
						columnAutoWidth: true,
						showBorders: true,
						columnChooser: {
							enabled: true,
						},
						columnFixing: {
							enabled: true,
						},
						columns: [
							{
								caption: dataGirdColumnName.date,
								dataField: 'date',
								dataType: 'datetime',
								format: 'dd/MM/yyyy HH:mm:ss',
								allowSearch: false,
							},
							{
								caption: dataGirdColumnName.user,
								dataField: 'user',
								cellTemplate: 'projectUser',
								filterOperations: ['contains', '='],
								lookup: {
									dataSource: lookupUserDataSource,
									valueExpr: 'memberId',
									displayExpr: 'memberLabel',
								},
							},
							{
								caption: dataGirdColumnName.filename,
								filterOperations: ['contains', '='],
								dataField: 'fileName',
							},
							{
								caption: dataGirdColumnName.status,
								alignment: 'center',
								cellTemplate: 'projectStatus',
								dataField: 'status',
								allowFiltering: false,
								allowHeaderFiltering: true,
								width: 120,
							},
							{
								caption: dataGirdColumnName.result,
								alignment: 'center',
								cellTemplate: 'projectResult',
							},
						],
					};

					vm.initHistory = function () {
						$scope.dataGridOptions = dataGridOptions;
					};

					function downloadFile(execId, link) {
						let downloadUrl = ProjectService.downloadExportArchive(
							execId,
							link
						);
						window.open(downloadUrl, '_blank');
					}

					function getProjectExportHistory(response) {
						return response.map(function (item) {
							return {
								projectId: item.project_id,
								date: item.date,
								user: item.user,
								status: item.status,
								executionId: item.execution_id,
								fileName: item.result ? item.result.file_name : '',
								link: item.result ? item.result.link : '',
							};
						});
					}

					function createCondition(filter, options, exclude) {
						if (filter !== undefined) {
							if (typeof filter !== 'string' && typeof filter[0] === 'string') {
								if (filter[0] === '!') {
									createCondition(filter[1], options, true);
								} else {
									if (filter[2] !== undefined && filter[2] !== null) {
										options.push({
											field: filter[0],
											operator: exclude ? '!' + filter[1]: filter[1],
											value: filter[0] === 'date' ? moment(filter[2]).toISOString(): filter[2],
										});
									} else {
										options.push({
											field: filter[0],
											operator: exclude ? '!' + filter[1]: filter[1],
										});
									}
								}
							} else if (filter[0] instanceof Array) {
								if (filter[1] === 'and') {
									options.push({
										condition: exclude ? 'or': 'and',
										options: [],
									});
								} else if (filter[1] === 'or') {
									options.push({
										condition: exclude ? 'and': 'or',
										options: [],
									});
								}
								for (let i = 0; i < filter.length; i++) {
									createCondition(filter[i], options[options.length-1].options, exclude);
								}
							}
						}
					}
				},
			];
			return buildDirectiveReturnObject(
				{ showProjectHistory: '=', data: '=' },
				controller,
				'./src/components/directives/exporthistory/projectExportHistory.html',
				function postLink(scope, element, attrs) {
					initElementForDirective(element, attrs, function () {
						scope.vm.showProjectHistory = false;
						scope.vm.data = {};
					});
					watchElementForDirective(
						element,
						scope,
						function () {
							return scope.vm.showProjectHistory;
						},
						function () {
							scope.vm.initHistory();
						},
						true
					);
				}
			);
		},
	]);
})();
