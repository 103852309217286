(function () {
	'use strict';

	angular.module('dcApp').directive('monitoring', [
		'$parse',
		function ($parse) {
			var controller = [
				'MonitoringServices',
				'$scope',
				'$rootScope',
				'toaster',
				'gettextCatalog',
				function (
					MonitoringServices,
					$scope,
					$rootScope,
					toaster,
					gettextCatalog
				) {
					let successTra = gettextCatalog.getString('Succès');
					let vm = this;
					$rootScope.disableAjaxLoading = true;

					let getFsData = function (level, setItems) {
						return MonitoringServices.getFsMemoryStats(
							vm.sparkContext,
							level
						).then(function (response) {
							let resp = angular.copy(response.data);
							let counter = 1;
							for (let i in resp) {
								if (level == 0) {
									resp[i].id = '1';
									resp[i].parentId = resp[i].parent_id;
								} else {
									resp[i].id = '1_' + counter;
									resp[i].parentId = '1';
								}
								resp[i].hasItems = resp[i].has_items;
								delete resp[i].has_items;
								delete resp[i].parent_id;
								counter++;
							}
							if (setItems) {
								$scope.treeViewInstance.option('items', resp);
							}

							return resp;
						});
					};

					$scope.treeViewOptions = {
						createChildren: function (parent) {
							let level = parent ? 1 : 0;
							return getFsData(level, false);
						},
						dataStructure: 'plain',
						itemTemplate: 'tvTemplate',
						rootValue: '',
						height: 500,
						onItemCollapsed: function (e) {
							getFsData(0, true);
						},
						onInitialized: function (e) {
							$scope.treeViewInstance = e.component;
						},
					};

					$scope.deleteExportedData = false;
					$scope.freeDisk = function () {
						let freeExportP = !!$scope.deleteExportedData;
						MonitoringServices.freeDisk(vm.sparkContext, freeExportP).then(
							function (response) {
								$scope.deletedElements = response.data;
								$scope.visiblePopup = true;
								toaster.pop(
									'success',
									successTra,
									gettextCatalog.getString('Opération effectuée')
								);
							}
						);
					};

					$scope.visiblePopup = false;
					$scope.popupOptions = {
						width: 700,
						height: 'auto',
						maxHeight: 700,
						showTitle: true,
						title: gettextCatalog.getString(
							'Liste des dossiers vidés ou supprimés'
						),
						position: { my: 'top', at: 'top', of: window, offset: '0 30' },
						contentTemplate: 'deletedElementList',
						dragEnabled: false,
						hideOnOutsideClick: true,
						shading: false,
						bindingOptions: {
							visible: 'visiblePopup',
						},
						onHidden: function () {
							$scope.visiblePopup = false;
						},
					};
					$scope.scrollViewOptions = {
						width: '100%',
						height: '100%',
					};
				},
			];

			return {
				restrict: 'E',

				scope: {
					sparkContext: '=',
				},
				controller: controller,
				controllerAs: 'vm',
				bindToController: true,
				templateUrl: './src/components/directives/monitoring/monitoring.html',
				transclude: true,
				replace: true,
				link: function postLink(scope, element, attrs) {
					scope.init();
				},
			};
		},
	]);
})();
