(function () {
	'use strict';

	angular.module('dcApp').service('AppSelectorService', AppSelectorService);

	AppSelectorService.$inject = [
		'$q',
		'$window',
		'$rootScope',
		'LoggerFactory',
		'ProjectStorage',
		'PROJECT_VAR_NAME',
		'PROJECT_LABEL_VAR_NAME',
		'STORAGE_SUFFIX',
		'CTX_VAR_NAME',
	];

	function AppSelectorService(
		$q,
		$window,
		$rootScope,
		LoggerFactory,
		ProjectStorage,
		PROJECT_VAR_NAME,
		PROJECT_LABEL_VAR_NAME,
		STORAGE_SUFFIX,
		CTX_VAR_NAME
	) {
		const logger = LoggerFactory.getLogger('AppSelectorService', false);
		let selectedProjectId = null;
		let selectedProjectLabel = null;
		let selectedCtx = null;

		this.setSelectedProject = setSelectedProject;
		this.setSelectedContext = setSelectedContext;
		this.clearProjectSelection = clearProjectSelection;
		this.getSelectedProjectId = getSelectedProjectId;
		this.getSelectedProjectLabel = getSelectedProjectLabel;
		this.getSelectedCtxId = getSelectedCtxId;

		function getSelectedProjectId() {
			if (!isEmpty(selectedProjectId)) {
    		return selectedProjectId;
			}
			selectedProjectId = $window.localStorage.getItem(PROJECT_VAR_NAME);
			if (selectedProjectId === null) {
				logger.warn('attempting to use project id before selecting a project');
			}

			return selectedProjectId;
		}

		function getSelectedProjectLabel() {
			if (!isEmpty(selectedProjectLabel)) {
				return selectedProjectLabel;
			}
			selectedProjectLabel = $window.localStorage.getItem(PROJECT_LABEL_VAR_NAME);
			if (selectedProjectLabel === null) {
				logger.warn('attempting to use project id before selecting a project');
			}

			return selectedProjectLabel;
		}

		function getSelectedCtxId() {
			if (selectedCtx === null) {
				logger.warn('no context is available');
			}
			return selectedCtx;
		}

		function setSelectedContext(ctxId) {
			selectedCtx = ctxId;
			$window.sessionStorage.setItem(CTX_VAR_NAME, ctxId);
			$window.localStorage.setItem(CTX_VAR_NAME, ctxId);
		}

		function setSelectedProject(projectId, projectLabel) {
			selectedProjectId = projectId;
			selectedProjectLabel = projectLabel;
			$rootScope.currentProjectId = projectId;

			$window.sessionStorage.setItem(PROJECT_VAR_NAME, projectId);
			$window.sessionStorage.setItem(PROJECT_LABEL_VAR_NAME, projectLabel);
			$window.localStorage.setItem(PROJECT_VAR_NAME, projectId);
			$window.localStorage.setItem(PROJECT_LABEL_VAR_NAME, projectLabel);
			ProjectStorage.setValue({
				projectId: parseInt(`${projectId}`, 10),
				projectLabel: projectLabel,
			});
			$rootScope.currentProject = projectLabel;
			$rootScope.currentProjectId = projectId;
			clearElementsListConf();
			$rootScope.$broadcast('update:project:selection');
			return $q.resolve();
		}

		function clearProjectSelection() {
			$window.sessionStorage.removeItem(PROJECT_VAR_NAME);
			$window.sessionStorage.removeItem(PROJECT_LABEL_VAR_NAME);
			$window.localStorage.removeItem(PROJECT_VAR_NAME);
			$window.localStorage.removeItem(PROJECT_LABEL_VAR_NAME);
			clearElementsListConf();
		}

		/**
		 * Private functions
		 */

		function clearElementsListConf() {
			let filterKeys = _.filter(_.keys($window.localStorage), function (key) {
				return key.endsWith(STORAGE_SUFFIX);
			});
			_.each(filterKeys, function (key) {
				$window.localStorage.removeItem(key);
			});
		}
	}
})();
