(function () {
	'use strict';

	angular.module('dcApp').directive('projectCard', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'$rootScope',
				'$state',
				'gettextCatalog',
				'ModalService',
				'API_BASE_URL_BACKEND',
				'ProjectService',
				'AppSelectorService',
				function (
					$scope,
					$rootScope,
					$state,
					gettextCatalog,
					ModalService,
					API_BASE_URL_BACKEND,
					ProjectService,
					AppSelectorService
				) {
					var vm = this;
					vm.descriptionTruncateThresh = 200;

					$scope.accessToken = window._keycloak.token;
					$scope.API_BASE_URL_BACKEND = API_BASE_URL_BACKEND;
					$scope.tagBox = {
						displayExpr: 'code',
						valueExpr: 'id',
						searchEnabled: false,
						editEnabled: false,
						tagTemplate: 'tagTemplate',
					};
					$scope.currentDate = moment(new Date()).format('LLLL');
					$scope.isMembersListVisible = false;

					vm.init = function (data) {
						vm.tooltipOptions = {
							target: '#members' + data.id,
							bindingOptions: {
								visible: 'isMembersListVisible',
							},
						};

						vm.metadataInfo = {
							id: data.id,
							code: data.metadata.code,
							updateDate: data.metadata.update_date,
							creationDate: data.metadata.creation_date,
							createdBy: data.metadata.created_by,
							updatedBy: data.metadata.updated_by,
							metadataId: data.metadata.id,
						};
					};

					$scope.adminProjectMembers = [];

					$scope.getTagBox = function (data) {
						$scope.tagBox.value = data.tags;
						return $scope.tagBox;
					};

					$scope.setDefault = function () {
						if (vm.data.inactive) {
							return;
						}
						vm.data.default_project = !vm.data.default_project;
						ProjectService.setDefaultProject(
							vm.data.id,
							vm.data.default_project)
							.then(function (response) {
								$scope.vm.afterSetDefault(vm.data.id, vm.data.default_project);
							});
					};

					$scope.setFavorite = function () {
						if (vm.data.inactive) {
							return;
						}
						vm.data.favorite_project = !vm.data.favorite_project;
						ProjectService.setFavoriteProject(
							vm.data.id,
							vm.data.favorite_project
						).then(function (response) {});
					};

					$scope.showProjectMembers = function () {
						ProjectService.getMembersByProjectId(vm.data.id).then(function (
							data
						) {
							$scope.adminProjectMembers = data.filter(
								(member) => member.admin
							);
							$scope.isMembersListVisible = true;
						});
					};

					$scope.showMailForm = function (member) {
						$scope.vm.sendMailData.projectId = vm.data.id;
						$scope.vm.sendMailData.member = member;
						$scope.isMembersListVisible = false;
						$scope.vm.sendMailData.showSendMailModal = true;
					};

					$scope.goIn = function () {
						$rootScope.$broadcast('update:menu:selection', true); // TODO: remove
						$state.go('gd-home', { projectId: vm.data.id });
					};

					$scope.editProject = function () {
						$state.go('projects-edit', { projectId: vm.data.id });
					};

					$scope.deleteProject = function () {
						var deleteProjectModalParams = {
							moduleName: 'deleteProject',
							modalName: 'deleteProject',
							controllerName: 'DeleteProjectController',
							dependencies: {
								title:
									gettextCatalog.getString('Suppression du projet') +
									':' +
									' ' +
									vm.data.metadata.label,
								projectId: vm.data.id,
								projectName: vm.data.metadata.label,
							},
						};
						var cannotDeleteProjectModalParams = {
							moduleName: 'deleteProject',
							modalName: 'cannotDeleteProjectModal',
							controllerName: 'cannotDeleteProjectController',
							dependencies: {
								title: gettextCatalog.getString('Suppression impossible !'),
								projectName: vm.data.metadata.label,
							},
						};
						ProjectService.usedByOtherProjectElements(vm.data.id).then(
							(response) => {
								var isUsedByOtherProject = response.data;
								if (isUsedByOtherProject) {
									openModal(cannotDeleteProjectModalParams);
								} else {
									openModal(deleteProjectModalParams);
								}
							}
						);
					};

					$scope.duplicateProject = function () {
						alert('Not yet available');
					};

					function openModal(modalParams) {
						if (!modalParams.modalName || !modalParams.controllerName) {
							return;
						}
						ModalService.open({
							controllerAs: 'vm',
							bindToController: true,
							templateUrl:
								'./src/components/modules/project/' +
								modalParams.moduleName +
								'/' +
								modalParams.modalName +
								'.html',
							controller: modalParams.controllerName,
							disableBackdrop: true,
							locals: modalParams.dependencies
								? modalParams.dependencies
								: undefined,
						});
					}
				},
			];

			return {
				restrict: 'E',

				scope: {
					data: '=',
					afterSetDefault: '=',
					deleteAction: '=',
					selectionEnabled: '=',
					adminEnabled: '=',
					memberEnabled: '=',
					sendMailData: '=',
					isNew: '=',
					config: '=',
				},
				controller: controller,
				controllerAs: 'vm',
				bindToController: true,
				templateUrl:
					'./src/components/directives/project/projectCard/projectCard.html',
				transclude: true,
				replace: true,
				link: function postLink($scope, element, attrs, $ctrl) {
					$scope.$watch(
						function () {
							return $ctrl.data.id;
						},
						function (newVal) {
							$ctrl.init($ctrl.data);
						}
					);
				},
			};
		},
	]);
})();
