(function () {
	'use strict';

	angular.module('dcApp').directive('mapview', [
		'$parse',
		function ($parse) {
			var controller = [
				'$log',
				'$state',
				'$rootScope',
				'$scope',
				'$http',
				'API_BASE_URL_BACKEND',
				'MapService',
				'hdSourceService',
				'$timeout',
				'$window',
				'PermissionService',
				'gettextCatalog',
				'CommonServices',
				'DateService',
				function (
					$log,
					$state,
					$rootScope,
					$scope,
					$http,
					API_BASE_URL_BACKEND,
					MapService,
					hdSourceService,
					$timeout,
					$window,
					PermissionService,
					gettextCatalog,
					CommonServices,
					DateService
				) {
					let vm = this;

					let map;
					let layerControl;
					let removedLayers = [];
					let baseMaps = {};
					let createdLayersGroup = {};
					let markersTab = [];
					let drawingManager;
					let legendDiv;
					let searchControl;
					let isDirectionsShownTab = [];
					let layerGroupsSettings = {};
					let elementsBounds;
					let legend = L.control({ position: 'bottomleft' });
					legend.onAdd = function () {
						legendDiv = getMapLegend();
						return legendDiv;
					};

					$scope.loadAll = function () {
						for (var i in $scope.groups) {
							$scope.groups[i].dataviewData.exec();
						}
					};

					$scope.rerun = function () {
						$scope.showTableData = false;
						vm.initialize(false);
					};

					$scope.edit = function () {
						const url = $state.href('maps-edit', {
							id: vm.data.mapId,
						});
						$window.open(url, '_blank');
						return;
					};

					$scope.switchDataVisibility = function () {
						$scope.showTableData = !$scope.showTableData;
					};

					$scope.rerunWithoutCache = function () {
						vm.initialize(true);
					};

					// fill or refill map
					$scope.fillMap = function (ignoreCache) {
						$scope.groups = [];

						if (!vm.data.full_data) {
							MapService.getMapConfig(vm.data.mapId).then(function (response) {
								$scope.mapConfig = response.data;
								initMapConfig(false, ignoreCache);
							});
						} else {
							$scope.mapConfig = vm.data.full_data.config;
							initMapConfig(true, ignoreCache);
						}
					};

					vm.initAll = function () {
						$scope.invalidData = [];
						$scope.API_BASE_URL_BACKEND = API_BASE_URL_BACKEND;
						$scope.accessToken =
							window._keycloak && window._keycloak.token
								? window._keycloak.token
								: '';
						$scope.mapSpinner = false;

						$scope.colorsTab = [
							'F9A441',
							'B80F4E',
							'499C53',
							'17B4E8',
							'E36286',
							'3BD476',
							'FF0000',
							'00FF00',
							'0000FF',
						];
						$scope.markerColorsTab = [
							"{color: '#" + 'FF0000' + "'}",
							"{color: '#" + '00FF00' + "'}",
							"{color: '#" + '0000FF' + "'}",
						];
						if (!vm.data.full_data) {
							PermissionService.havePermission(
								vm.data.mapId,
								'edit',
								'map'
							).then(function (response) {
								$scope.editIsPermitted = response.data;
							});
						}
					};

					$scope.toggleAddressZone = function () {
						if (!searchControl) {
							initMapPlaceId();
						}

						if ($scope.mapConfig.global.address_zone_shown) {
							map.addControl(searchControl);
						} else {
							map.removeControl(searchControl);
						}
					};

					$scope.toggleDragDrop = function () {
						initMapDragDrop();
					};

					$scope.toggleDrawingZone = function () {
						if ($scope.mapConfig.global.drawing_zone_shown) {
							initMapDrawing();
						} else if (drawingManager) {
							drawingManager.setMap(null);
						}
					};

					$scope.toggleLegendZone = function () {
						if ($scope.mapConfig.global.legend_zone_shown) {
							legend.addTo(map);
						} else {
							if (legendDiv) {
								map.removeControl(legend);
							}
						}
					};

					$scope.isUrl = function (str) {
						if (str && typeof str === 'string') {
							str = str.toLowerCase();
							return str.startsWith('http://') || str.startsWith('https://');
						} else {
							return false;
						}
					};

					$scope.showMarker = function (row, group) {
						var index = row.rowIndex;
						var markerL = markersTab[group.id][index].markersL[0]
							? markersTab[group.id][index].markersL[0]
							: undefined;
						if (!markerL) {
							return;
						}

						for (var e in group.config.elements) {
							if (group.config.elements[e].type == 'POINT') {
								var contentString = getTooltipHtml(
									group.config.elements[e],
									row.data,
									group.columns
								);
								break;
							}
						}

						$timeout(function () {
							let popup = L.popup({ maxHeight: 300 }).setContent(contentString);
							markerL.bindPopup(popup);
							markerL.addTo(map);
							map.panTo(markerL.getLatLng());
						}, 100);
					};

					$scope.showDirections = function (group) {
						isDirectionsShownTab[group.id] = !isDirectionsShownTab[group.id];
						if (isDirectionsShownTab[group.id]) {
							for (var k = 0; k < markersTab[group.id].length; k++) {
								for (var m in markersTab[group.id][k].directions) {
									markersTab[group.id][k].directions[m].setMap(map);
								}
							}
						} else {
							for (var k = 0; k < markersTab[group.id].length; k++) {
								for (var d in markersTab[group.id][k].directions) {
									markersTab[group.id][k].directions[d].setMap(null);
								}
							}
						}
					};

					$scope.setColor = function (group) {
						var index = $scope.groups.indexOf(group);
						var markerColor = {
							color: "'#" + group.color + "'",
						};
						return markerColor;
					};

					// Display map elments (markers, shapes, circles and routes)
					$scope.showMapElements = function (group) {
						for (let k in markersTab[group.id]) {
							let elements = markersTab[group.id][k].markersL;
							if (elements && elements[0] && elements[0].cluster) {
								let markerCluster = new L.MarkerClusterGroup();
								for (let m in elements) {
									markerCluster.addLayer(elements[m]);
								}
								elements = [markerCluster];
							}
							elements = _.union(
								elements,
								markersTab[group.id][k].shapesL,
								markersTab[group.id][k].circlesL
							);
							if (!createdLayersGroup[k]) {
								let elementAsGroupL = L.featureGroup(elements);
								elementAsGroupL.idElementP = k;
								createdLayersGroup[k] = elementAsGroupL;
								elementAsGroupL.addTo(map);
								let htmlLayerControl = getLayerControlHtml(k, false);
								let elementConf = layerGroupsSettings[k];
								layerControl.addOverlay(
									elementAsGroupL,
									htmlLayerControl,
									elementConf && elementConf.groupLabel
										? elementConf.groupLabel
										: ' '
								);
								elementsBounds =
									elementsBounds != undefined
										? elementsBounds.extend(elementAsGroupL.getBounds())
										: elementAsGroupL.getBounds();
							}
						}
					};

					vm.initialize = function (ignoreCache) {
						if ($scope.jobDetails && $scope.jobDetails.init) {
							$scope.jobDetails.init();
						}
						$scope.fillMap(ignoreCache);
					};

					function reInitLegend() {
						map.removeControl(legend);
						legend.addTo(map);
					}

					function initializeLeafLetMap() {
						let tiles = $scope.mapConfig.global.tiles_config;
						let layers = [];
						for (let i in tiles) {
							let tileConf = {
								maxZoom: 18,
								tileSize: 256,
								center: [45.2230485093511, 5.8184611026915],
								zoomOffset: 0,
								fullscreenControl: true,
							};
							let providerName = tiles[i].tile_provider_name;
							if (tiles[i].tile_provider_api_key) {
								tileConf.accessToken = tiles[i].tile_provider_api_key;
							}
							if (tiles[i].tile_provider_id) {
								tileConf.id = tiles[i].tile_provider_id;
								// for mapbox
								let elementWithSameName = _.filter(tiles, function (elm) {
									return elm.tile_provider_name == providerName;
								});
								providerName =
									elementWithSameName && elementWithSameName.length > 1
										? providerName + ' (' + tileConf.id + ')'
										: providerName;
							}
							let tile = L.tileLayer(
								tiles[i].tile_provider_url ? tiles[i].tile_provider_url : '',
								tileConf
							);
							layers.push(tile);
							baseMaps[providerName] = tile;
						}
						if (!map) {
							map = L.map('map' + $scope.uuid, { layers: layers }).setView(
								[45.2230485093511, 5.8184611026915],
								6
							);
							map.addControl(new L.Control.Fullscreen());
							map.on('overlayadd', function (eventLayer) {
								map.fitBounds(eventLayer.target.getBounds());
								removedLayers.splice(
									removedLayers.indexOf(eventLayer.layer.idElementP)
								);
								reInitLegend();
							});
							map.on('overlayremove', function (eventLayer) {
								removedLayers.push(eventLayer.layer.idElementP);
								reInitLegend();
							});
							map.on('easyPrint-start', function (ev) {
								$scope.mapSpinner = true;
							});
							map.on('easyPrint-finished', function (ev) {
								$scope.mapSpinner = false;
							});
							toggleMapMenu();
							// export
							L.easyPrint({
								title: gettextCatalog.getString('Exporter'),
								exportOnly: true,
								hideControlContainer: true,
								sizeModes: ['A4Portrait', 'A4Landscape'],
							}).addTo(map);
						}
						layerControl = L.control.groupedLayers(baseMaps);
						layerControl.addTo(map);
					}

					function getTooltipHtml(mapElement, data, columns) {
						if (
							!mapElement.tooltip_columns ||
							!mapElement.tooltip_columns[0] ||
							!_.filter(mapElement.tooltip_columns, function (item) {
								return _.find(columns, function (col) {
									return col.uuid == item;
								});
							})[0]
						) {
							return;
						}
						var str = '';

						for (var k in mapElement.tooltip_columns) {
							var col = _.find(columns, function (item) {
								return item.uuid == mapElement.tooltip_columns[k];
							});
							var value = data[mapElement.tooltip_columns[k]].val;

							if ($scope.isUrl(value)) {
								str =
									str +
									' ' +
									col.lib +
									" : <a href='" +
									value +
									"' target='_blank'>" +
									value +
									'</a></br> ';
							} else {
								let formattedValue;
								if (mapElement && mapElement.tooltip_columns_patterns && col.uuid in mapElement.tooltip_columns_patterns) {
									let tooltipPattern = mapElement.tooltip_columns_patterns[col.uuid];
									let pattern = tooltipPattern ? tooltipPattern.pattern : null;
									if (col.type === "date") {
										if (value instanceof Array)  {
											formattedValue = value.map(v => DateService.dateToStringWithPatternAndTZ(v, pattern, $rootScope.getDefaultTimezone())).join("; ")
										} else {
											formattedValue = DateService.dateToStringWithPatternAndTZ(
												value,
												pattern,
												$rootScope.getDefaultTimezone()
											);
										}
									} else if (col.type === "decimal" || col.type === 'integer' || col.type === 'big_integer') {
											formattedValue = CommonServices.formatNumber(value, tooltipPattern);
									}
								} else {
									formattedValue = value;
								}
								str = str + ' ' + col.lib + ' : ' + formattedValue + '</br> ';
							}
						}
						return '<html><body><div><p>' + str + '</p></div></body></html>';
					}

					function loadElementInMap(data, mapElement, group, i) {
						var color;

						if (mapElement.color_column != undefined) {
							color = data[mapElement.color_column].val;
						}

						if (color) {
							mapElement.color = color;
						}

						if (mapElement.icon_column != undefined) {
							var icon = data[mapElement.icon_column].val;
						}

						if (mapElement.type == 'POINT') {
							if (
								mapElement.column != undefined &&
								mapElement.second_column != undefined
							) {
								var lat = data[mapElement.column].val;
								var lng = data[mapElement.second_column].val;
							} else if (mapElement.third_column != undefined) {
								var location = data[mapElement.third_column].val;
								if (location) {
									try {
										location = JSON.parse(location);
										var lat = location.lat;
										var lng = location.lng;
									} catch (err) {
										if ($scope.invalidData.indexOf(group.lib) == -1) {
											$scope.invalidData.push(group.lib);
										}
									}
								}
							}

							if (typeof isNumeric(lat) && isNumeric(lng)) {
								var contentString = getTooltipHtml(
									mapElement,
									data,
									group.columns
								);

								var imgUrl =
									'https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|';
								if (icon) {
									imgUrl = icon;
								} else if (mapElement.icon) {
									imgUrl =
										$scope.API_BASE_URL_BACKEND +
										'pictogramme_image?id=' +
										mapElement.icon;
								}

								let customMarkerIcon = L.icon({
									iconSize: new L.Point(25, 41),
									iconAnchor: new L.Point(12, 41),
									iconUrl: imgUrl,
								});
								let latLngL = new L.LatLng(lat, lng);
								let lMarker = L.marker(latLngL, { icon: customMarkerIcon });

								if (contentString) {
									let popup = L.popup({ maxHeight: 300 }).setContent(
										contentString
									);
									lMarker.bindPopup(popup);
								}
								lMarker.cluster = mapElement.cluster;
								markersTab[group.id][mapElement.id].markersL.push(lMarker);
							} else {
								if ($scope.invalidData.indexOf(group.lib) == -1) {
									$scope.invalidData.push(group.lib);
								}
							}
						} else if (mapElement.type == 'DIRECTION') {
							var direction = data[mapElement.column].val;
							if (direction) {
								try {
									direction = JSON.parse(direction);
									var directionsDisplay = new google.maps.DirectionsRenderer({
										polylineOptions: { strokeColor: mapElement.color },
									});

									directionsDisplay.setOptions({ suppressMarkers: true });

									if (
										direction &&
										direction.result &&
										direction.result.routes[0]
									) {
										typecastRoutes(direction.result.routes);
										direction.request.travelMode =
											direction.request.travel_mode;
										directionsDisplay.setOptions({
											directions: {
												routes: direction.result.routes,
												request: direction.request,
											},
										});
									}
									directionsDisplay.setMap(map);

									markersTab[group.id][i].directions.push(directionsDisplay);
								} catch (err) {
									$log.error('Error creating point --->', err);
									if ($scope.invalidData.indexOf(group.lib) == -1) {
										$scope.invalidData.push(group.lib);
									}
								}
							}
						} else if (mapElement.type == 'SHAPE') {
							let shape = data[mapElement.column].val;
							let contentString = getTooltipHtml(
								mapElement,
								data,
								group.columns
							);
							let strokeColorCol = data[mapElement.stroke_color_column]
								? data[mapElement.stroke_color_column].val
								: mapElement.stroke_color;
							strokeColorCol = strokeColorCol
								? strokeColorCol
								: mapElement.color;
							let outlineWeight = mapElement.shape_border_thickness
								? mapElement.shape_border_thickness
								: 3;
							layerGroupsSettings[mapElement.id].colors = layerGroupsSettings[
								mapElement.id
							].colors
								? layerGroupsSettings[mapElement.id].colors
								: [];
							if (
								layerGroupsSettings[mapElement.id].colors.indexOf(
									mapElement.color
								) < 0
							) {
								layerGroupsSettings[mapElement.id].colors.push(
									mapElement.color
								);
							}
							if (shape) {
								try {
									shape = JSON.parse(shape);
									let shapeL = L.geoJSON(shape, {
										style: {
											fillColor: mapElement.color,
											color: strokeColorCol,
											fillOpacity:
												mapElement.opacity != undefined
													? mapElement.opacity
													: 0.35,
											weight: mapElement.border ? outlineWeight : 0,
										},
									});

									if (contentString) {
										let popup = L.popup({ maxHeight: 300 }).setContent(
											contentString
										);
										shapeL.bindPopup(popup);
									}
									markersTab[group.id][mapElement.id].shapesL.push(shapeL);
								} catch (err) {
									$log.error('Error creating shape --->', err);
									if ($scope.invalidData.indexOf(group.lib) == -1) {
										$scope.invalidData.push(group.lib);
									}
								}
							}
						} else if (mapElement.type == 'CIRCLE') {
							try {
								let lat;
								let lng;
								if (
									mapElement.column != undefined &&
									mapElement.second_column != undefined
								) {
									lat = data[mapElement.column].val;
									lng = data[mapElement.second_column].val;
								}

								let population;
								if (mapElement.third_column) {
									population = data[mapElement.third_column].val;
								}
								if (
									typeof isNumeric(lat) &&
									isNumeric(lng) &&
									isNumeric(population)
								) {
									let latLng = new L.LatLng(lat, lng);
									layerGroupsSettings[mapElement.id].colors =
										layerGroupsSettings[mapElement.id].colors
											? layerGroupsSettings[mapElement.id].colors
											: [];
									if (
										layerGroupsSettings[mapElement.id].colors.indexOf(
											mapElement.color
										) < 0
									) {
										layerGroupsSettings[mapElement.id].colors.push(
											mapElement.color
										);
									}
									let circleStyle = {
										fillColor: mapElement.color,
										color: mapElement.stroke_color
											? mapElement.stroke_color
											: mapElement.color,
										weight: mapElement.border ? 3 : 0,
										fillOpacity:
											mapElement.opacity != undefined
												? mapElement.opacity
												: 0.35,
										radius: population,
									};

									let circleL = L.circle(latLng, circleStyle);

									markersTab[group.id][mapElement.id].circlesL.push(circleL);

									var contentString = getTooltipHtml(
										mapElement,
										data,
										group.columns
									);
									if (contentString) {
										let popup = L.popup({ maxHeight: 300 }).setContent(
											contentString
										);
										circleL.bindPopup(popup);
									}
								} else {
									if ($scope.invalidData.indexOf(group.lib) == -1) {
										$scope.invalidData.push(group.lib);
									}
								}
							} catch (err) {
								$log.error('Error creating circle --->', err);
								if ($scope.invalidData.indexOf(group.lib) == -1) {
									$scope.invalidData.push(group.lib);
								}
							}
						}
					}

					function typecastRoutes(routes) {
						routes.forEach(function (route) {
							route.bounds = asBounds(route.bounds);
							// I don't think `overview_path` is used but it exists on the
							// response of DirectionsService.route()
							route.overview_path = asPath(route.overview_polyline);

							route.legs.forEach(function (leg) {
								leg.start_location = asLatLng(leg.start_location);
								leg.end_location = asLatLng(leg.end_location);
								var steps = [];
								leg.steps.forEach(function (step) {
									step.start_location = asLatLng(step.start_location);
									step.end_location = asLatLng(step.end_location);
									if (step.start_location && step.end_location) {
										step.path = asPath(step.polyline);
										steps.push(step);
									}
								});
								leg.steps = steps;
							});
						});
					}

					function asBounds(boundsObject) {
						return new google.maps.LatLngBounds(
							asLatLng(boundsObject.southwest),
							asLatLng(boundsObject.northeast)
						);
					}

					function asLatLng(latLngObject) {
						return latLngObject
							? new google.maps.LatLng(latLngObject.lat, latLngObject.lng)
							: undefined;
					}

					function asPath(encodedPolyObject) {
						return google.maps.geometry.encoding.decodePath(
							encodedPolyObject.encoded_path
						);
					}

					// init drawing tools
					function initMapDrawing() {
						var editableLayers = new L.FeatureGroup();
						map.addLayer(editableLayers);
						var MyCustomMarker = L.Icon.extend({
							options: {
								shadowUrl: null,
								iconAnchor: new L.Point(12, 12),
								iconSize: new L.Point(24, 24),
								iconUrl: './src/img/beachflag.png',
							},
						});
						var options = {
							position: 'bottomright',
							draw: {
								polyline: {
									shapeOptions: {
										color: '#f357a1',
										weight: 10,
									},
								},
								polygon: {
									allowIntersection: true, // Restricts shapes to simple polygons
									shapeOptions: {
										color: '#bada55',
									},
								},
								circle: {
									shapeOptions: {
										clickable: true,
									},
								},
								rectangle: {
									shapeOptions: {
										clickable: true,
									},
								},
								marker: {
									icon: new MyCustomMarker(),
								},
							},
							edit: {
								featureGroup: editableLayers, //REQUIRED!!
								remove: true,
							},
						};
						let drawControl = new L.Control.Draw(options);
						map.addControl(drawControl);
						map.on(L.Draw.Event.CREATED, function (e) {
							var type = e.layerType,
								layer = e.layer;

							editableLayers.addLayer(layer);
						});
					}

					// init address search bar
					function initMapPlaceId() {
						let GeoSearchControl = window.GeoSearch.GeoSearchControl;
						let OpenStreetMapProvider = window.GeoSearch.OpenStreetMapProvider;
						let provider = new OpenStreetMapProvider();

						let iconCustomized = L.icon({
							iconSize: new L.Point(25, 41),
							iconAnchor: new L.Point(12, 41),
							iconUrl: './src/img/marker-icon-violet.png',
						});
						searchControl = new GeoSearchControl({
							provider: provider,
							style: 'bar',
							autoComplete: true,
							searchLabel: gettextCatalog.getString('Entrez une adresse'),
							retainZoomLevel: true,
							marker: {
								icon: iconCustomized,
							},
						});
						map.on('geosearch/showlocation', function (results, status) {
							if (
								results &&
								results.location &&
								results.location.y &&
								results.location.x
							) {
								map.setView([results.location.y, results.location.x], 8);
							}
						});
					}

					// construct map legend
					function getMapLegend() {
						let legDiv = L.DomUtil.create('div', 'map-legend');
						// Stop wheeling event propagation  to map
						L.DomEvent.on(legDiv, 'mousewheel', L.DomEvent.stopPropagation);
						let layerGroupsSettingsKeys = Object.keys(layerGroupsSettings);
						for (let e in layerGroupsSettingsKeys) {
							if (removedLayers.indexOf(layerGroupsSettingsKeys[e]) < 0) {
								legDiv.innerHTML +=
									'<div class="ellipsis-text" title="' +
									getMapElementLabel(layerGroupsSettingsKeys[e]) +
									'"> ' +
									getLayerControlHtml(layerGroupsSettingsKeys[e], true) +
									'</div>';
							}
						}

						return legDiv;
					}

					function initMapDragDrop() {
						initEvents();
					}

					//Grag drop
					function initEvents() {
						// set up the drag & drop events
						var mapContainer = document.getElementById('map' + $scope.uuid);
						var dropContainer = document.getElementById(
							'drop-container' + $scope.uuid
						);

						if ($scope.mapConfig.global.drag_drop_activated) {
							// map-specific events
							mapContainer.addEventListener('dragenter', showPanel, false);

							// overlay specific events (since it only appears once drag starts)
							dropContainer.addEventListener('dragover', showPanel, false);
							dropContainer.addEventListener('drop', handleDrop, false);
							dropContainer.addEventListener('dragleave', hidePanel, false);
						} else {
							mapContainer.removeEventListener('dragenter', showPanel);
							dropContainer.removeEventListener('dragover', showPanel);
							dropContainer.removeEventListener('drop', handleDrop);
							dropContainer.removeEventListener('dragleave', hidePanel);
						}
					}

					function zoom(map) {
						var bounds = new google.maps.LatLngBounds();
						map.data.forEach(function (feature) {
							processPoints(feature.getGeometry(), bounds.extend, bounds);
						});
						map.fitBounds(bounds);
					}

					function processPoints(geometry, callback, thisArg) {
						if (geometry instanceof google.maps.LatLng) {
							callback.call(thisArg, geometry);
						} else if (geometry instanceof google.maps.Data.Point) {
							callback.call(thisArg, geometry.get());
						} else {
							geometry.getArray().forEach(function (g) {
								processPoints(g, callback, thisArg);
							});
						}
					}

					function showPanel(e) {
						e.stopPropagation();
						e.preventDefault();
						document.getElementById(
							'drop-container' + $scope.uuid
						).style.display = 'block';
						return false;
					}

					function hidePanel(e) {
						document.getElementById(
							'drop-container' + $scope.uuid
						).style.display = 'none';
					}

					function loadGeoJsonString(geoString) {
						var geojson = JSON.parse(geoString);
						map.data.addGeoJson(geojson);
						//        zoom(map);
					}

					function handleDrop(e) {
						e.preventDefault();
						e.stopPropagation();
						hidePanel(e);

						var files = e.dataTransfer.files;
						if (files.length) {
							// process file(s) being dropped
							// grab the file data from each file
							for (var i = 0, file; (file = files[i]); i++) {
								var reader = new FileReader();
								reader.onload = function (e) {
									loadGeoJsonString(e.target.result);
								};
								reader.onerror = function (e) {
									$log.error('reading failed');
								};
								reader.readAsText(file);
							}
						} else {
							// process non-file (e.g. text or html) content being dropped
							// grab the plain text version of the data
							var plainText = e.dataTransfer.getData('text/plain');
							if (plainText) {
								loadGeoJsonString(plainText);
							}
						}

						// prevent drag event from bubbling further
						return false;
					}

					// get layer control and legend element html
					function getLayerControlHtml(element, bold) {
						let elementConf = layerGroupsSettings[element];
						let icon = '---';
						if (!elementConf) {
							return icon;
						}
						if (elementConf.type == 'POINT') {
							if (elementConf.icon !== undefined) {
								let imgUrl =
									$scope.API_BASE_URL_BACKEND +
									'pictogramme_image?id=' +
									elementConf.icon;
								icon =
									'<img class="icon-24" src="' +
									imgUrl +
									'" style="margin-right: 12px;margin-top: 5px;" />';
							} else {
								icon =
									'<i class="fa fa-map-marker fa-2x" style="margin-right: 16px;margin-top: 5px;" aria-hidden="true"></i>';
							}
						} else if (elementConf.type == 'SHAPE') {
							let colorStyle = getColorsStyle(elementConf);
							icon =
								'<i class="fas fa-hexagon fa-2x" style="' +
								colorStyle +
								'margin-right: 6px;margin-top: 5px;"></i>';
						} else if (elementConf.type == 'CIRCLE') {
							let colorStyle = getColorsStyle(elementConf);
							icon =
								'<i class="fas fa-circle fa-2x" style="' +
								colorStyle +
								';margin-right: 8px;margin-top: 5px;"></i>';
						}

						let labelText = elementConf.label ? elementConf.label : ' ';
						let label = bold
							? '<span style="font-weight: 700">' + labelText + '</span>'
							: '<span style="font-weight: 400">' + labelText + '</span>';

						return icon + ' ' + label;
					}

					function getColorsStyle(elementConf) {
						let colors = elementConf.colors;
						let color = elementConf.color ? elementConf.color : '#999';
						colors = colors ? colors : [color];
						let oneColor = elementConf.colors && elementConf.colors.length == 1;
						let colorsAsStr = oneColor
							? elementConf.colors[0]
							: elementConf.colors.join(', ');

						return oneColor
							? 'color: ' + colorsAsStr + ';'
							: 'background: linear-gradient(to right top,' +
									colorsAsStr +
									');-webkit-background-clip: text; -webkit-text-fill-color: transparent;';
					}

					function getMapElementLabel(elementKey) {
						let elementConf = layerGroupsSettings[elementKey];
						if (elementConf) {
							return elementConf.label;
						} else {
							return '';
						}
					}

					function loadGroupDataInMap(group, data) {
						if (!markersTab[group.id]) {
							markersTab[group.id] = [];
						}

						markersTab[group.id] = [];
						for (var e in group.config.elements) {
							markersTab[group.id][group.config.elements[e].id] = {
								markersL: [],
								markers: [],
								shapes: [],
								shapesL: [],
								directions: [],
								circles: [],
								circlesL: [],
							};
							for (var i in data) {
								loadElementInMap(data[i], group.config.elements[e], group, i);
							}
						}

						isDirectionsShownTab[group.id] = false;
						$scope.showMapElements(group);
						// Disable for now
						// $scope.showDirections(group)
					}

					function mapFitBounds(i) {
						if (
							i != undefined &&
							i + 1 == $scope.groups.length &&
							elementsBounds
						) {
							map.fitBounds(elementsBounds);
						}
					}

					$scope.showPopover = function ($event, obj) {
						if (Object.keys(obj).length === 0) {
							$scope.noFilterApplied = true;
						} else {
							$scope.noFilterApplied = false;
						}
						// Set a bigger z-index
						$(function () {
							DevExpress.ui.dxOverlay.baseZIndex(1999999998);
						});
					};

					function prepareGroups(i, ignoreCache) {
						$scope.groups[i].color = $scope.colorsTab[i];
						$scope.groups[i].dataviewData.showMarkerOption = true;
						// set unique id to elements
						for (let j in $scope.groups[i].config.elements) {
							let uniqueId = _.uniqueId('mapElm_');
							$scope.groups[i].config.elements[j].id = uniqueId;
							layerGroupsSettings[uniqueId] = {
								type: $scope.groups[i].config.elements[j].type,
								color: $scope.groups[i].config.elements[j].color,
								colorColumn: $scope.groups[i].config.elements[j].color_column,
								colors: [],
								icon: $scope.groups[i].config.elements[j].icon,
								label: $scope.groups[i].config.elements[j].label,
								groupId: $scope.groups[i].id,
								groupLabel: $scope.groups[i].lib,
							};
						}
						$scope.groups[i].dataviewData.showMarkerAction = function (item) {
							$scope.showMarker(item, $scope.groups[i]);
						};

						$scope.groups[i].dataviewData.afterDataLoaded = function (
							data,
							columns
						) {
							$scope.groups[i].columns = columns;
							loadGroupDataInMap($scope.groups[i], data);
							$scope.toggleLegendZone();
							mapFitBounds(i);
						};
						$scope.groups[i].dataviewData.jobDetails = $scope.jobDetails;
						$scope.groups[i].dataviewData.dataview = {
							source_id: $scope.groups[i].source_id,
							grammar: $scope.groups[i].grammar,
							lib: $scope.groups[i].lib,
							centering: vm.data.centering,
							ignore_cache_on_init: ignoreCache,
						};
					}

					function initPopover() {
						$scope.sourceCentring = vm.data.centering
							? vm.data.centering.source_centering
							: {};
						$scope.filterDetailsPopover = {
							target: '#filterInfoPopover' + $scope.uuid,
							showEvent: 'dxclick',
							position: 'top',
							width: 450,
							bindingOptions: {
								visible: 'visibleMetaData',
							},
						};
					}

					function initializeGroupsWithFullData(i, ignoreCache) {
						if (!$scope.mapConfig.groups[i]) {
							return;
						}
						let fullData = _.find(
							$scope.vm.data.full_data.sources,
							function (item) {
								return item.source_id == $scope.mapConfig.groups[i].source_id;
							}
						);
						$scope.mapConfig.groups[i].grammar = fullData.grammar;
						$scope.groups.push($scope.mapConfig.groups[i]);
						$scope.groups[i].dataviewData = {
							full_data: fullData,
							auto_execute: true,
							hide_operations: true,
							hdType: 'MAP',
							hdId: vm.data.mapId,
						};
						prepareGroups(i, ignoreCache);
						initializeGroupsWithFullData(i + 1, ignoreCache);
					}

					function initializeGroups(i, ignoreCache) {
						if (!$scope.mapConfig.groups[i]) {
							return;
						}
						initPopover();
						hdSourceService
							.getSource(
								$scope.mapConfig.groups[i].source_id,
								'MAP',
								vm.data.mapId
							)
							.then(function (response) {
								$scope.mapConfig.groups[i].grammar = response.data.grammar;
								$scope.groups.push($scope.mapConfig.groups[i]);
								$scope.groups[i].dataviewData = {
									auto_execute: true,
									hide_operations: true,
									hdType: 'MAP',
									hdId: vm.data.mapId,
								};
								prepareGroups(i, ignoreCache);
								initializeGroups(i + 1, ignoreCache);
							});
					}

					function initMapConfig(fullData, ignoreCache) {
						if (!$scope.mapConfig.global) {
							$scope.mapConfig.global = {};
						}
						// delete old elements
						for (let o in createdLayersGroup) {
							createdLayersGroup[o].eachLayer(function (layer) {
								createdLayersGroup[o].removeLayer(layer);
							});
							map.removeLayer(createdLayersGroup[o]);
							if (layerControl) {
								map.removeControl(layerControl);
							}
						}

						$timeout(function () {
							// init map fully or partially
							initializeLeafLetMap();
							layerGroupsSettings = {};
							elementsBounds = undefined;

							if (!fullData) {
								initializeGroups(0, ignoreCache);
							} else {
								initializeGroupsWithFullData(0, ignoreCache);
							}
						}, 500);
					}

					function toggleMapMenu() {
						$scope.toggleAddressZone();
						$scope.toggleDragDrop();
						$scope.toggleDrawingZone();
					}
				},
			];

			return {
				restrict: 'E',

				scope: {
					data: '=',
				},
				controller: controller,
				controllerAs: 'vm',
				bindToController: true,
				templateUrl:
					'./src/components/directives/handledata/mapView/mapView.html',
				transclude: true,
				replace: true,
				link: function postLink(scope, element, attrs, $ctrl) {
					$ctrl.initAll();
					scope.uuid = generateUuid('_');

					if (!$ctrl.data.full_data) {
						scope.jobDetails = { rerunMethod: scope.rerunWithoutCache };
						scope.jobDetailsId = generateUuid('_');
					}
					const ignoreCache = scope.vm.data.ignoreCache || false;
					element.ready(function () {
						$ctrl.initialize(ignoreCache);
					});
				},
			};
		},
	]);
})();
