(function () {
	'use strict';

	angular.module('dcApp').directive('exportFormula', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'$rootScope',
				'toaster',
				'gettextCatalog',
				function ($scope, $rootScope, toaster, gettextCatalog) {
					var vm = this;
					let formulaToExport;
					let dataExport;

					vm.exportPopupShow = function (data, multiple) {
						dataExport = data;
						if (!data.selectedElems || data.selectedElems.length <= 0) {
							toaster.error(
								'error',
								gettextCatalog.getString('Aucun élement Séléctionné')
							);
							return;
						}
						if (multiple) {
							$scope.exportFormulaFileName =
								'export_formula_dc_' +
								$rootScope.getDateTimeWithPatternMn(new Date());
							$scope.exportPopupOptionsTitle =
								gettextCatalog.getString('Export formules');
						} else {
							formulaToExport = _.find(data.elements, function (elm) {
								return elm.id == data.selectedElems[0];
							});
							if (!formulaToExport) {
								toaster.error(
									'error',
									gettextCatalog.getString('Action impossible')
								);
								return;
							}
							$scope.exportFormulaFileName =
								formulaToExport.metadata.label + '_formula_dc';
							$scope.exportPopupOptionsTitle =
								gettextCatalog.getString('Export formule :') +
								' ' +
								formulaToExport.metadata.label;
						}
						$scope.visibleExportPopup = true;
					};

					$scope.exportFormulas = function () {
						let formulasToExp = [];
						for (let i in dataExport.selectedElems) {
							formulaToExport = _.find(dataExport.elements, function (elm) {
								return elm.id == dataExport.selectedElems[i];
							});
							if (formulaToExport) {
								let formulaExportCopy = angular.copy(formulaToExport);
								delete formulaExportCopy.id;
								formulaExportCopy.metadata = {
									description: formulaExportCopy.metadata.description,
									label: formulaExportCopy.metadata.label,
									tags: formulaExportCopy.metadata.tags,
								};
								formulasToExp.push(formulaExportCopy);
							}
						}

						let dataStr = JSON.stringify(formulasToExp);
						let dataUri =
							'data:application/json;charset=utf-8,' +
							encodeURIComponent(dataStr);
						let linkElement = document.createElement('a');
						linkElement.setAttribute('href', dataUri);
						let validFileName = $scope.exportFormulaFileName.replace(
							/[^a-zA-Z0-9]/g,
							'_'
						);
						linkElement.setAttribute('download', validFileName);
						linkElement.click();
						$scope.visibleExportPopup = false;
					};

					$scope.exportPopupOptions = {
						width: 500,
						height: 170,
						shading: false,
						contentTemplate: 'exportFormulaTemplate',
						showTitle: true,
						dragEnabled: false,
						hideOnOutsideClick: true,
						showCloseButton: true,
						bindingOptions: {
							visible: 'visibleExportPopup',
							title: 'exportPopupOptionsTitle',
						},
					};
				},
			];

			return {
				restrict: 'E',

				scope: {
					data: '=?',
					showExportPopup: '=',
				},
				controller: controller,
				controllerAs: 'vm',
				bindToController: true,
				templateUrl:
					'./src/components/directives/dataOperationTools/formule/formulaExport/exportFormula.html',
				transclude: true,
				replace: true,
				link: function postLink(scope, element, attrs, $ctrl) {
					$ctrl.showExportPopup = $ctrl.exportPopupShow;
				},
			};
		},
	]);
})();
