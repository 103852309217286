(function () {
	'use strict';

	angular.module('dcApp').directive('datablockExportHistory', [
		'$parse',
		function ($parse) {
			var controller = [
				'API_BASE_URL_BACKEND',
				'$state',
				'$scope',
				'$rootScope',
				'$q',
				'toaster',
				'PAGINATIONS_SIZE',
				'PAGINATIONS_SIZES',
				'ExportHistoryService',
				'gettextCatalog',
				function (
					API_BASE_URL_BACKEND,
					$state,
					$scope,
					$rootScope,
					$q,
					toaster,
					PAGINATIONS_SIZE,
					PAGINATIONS_SIZES,
					ExportHistoryService,
					gettextCatalog
				) {
					const vm = this;

					let dataGirdColumnName = {};
					dataGirdColumnName.labelDatablock = gettextCatalog.getString(
						'datablock.export.history.label'
					);
					dataGirdColumnName.status = gettextCatalog.getString(
						'datablock.export.history.status'
					);
					dataGirdColumnName.type = gettextCatalog.getString(
						'datablock.export.history.type'
					);
					dataGirdColumnName.date = gettextCatalog.getString(
						'datablock.export.history.date'
					);
					dataGirdColumnName.result = gettextCatalog.getString(
						'datablock.export.history.result'
					);
					dataGirdColumnName.properties = gettextCatalog.getString(
						'datablock.export.history.properties'
					);
					dataGirdColumnName.user = gettextCatalog.getString(
						'datablock.export.history.user'
					);
					$scope.showElementsHistory = true;
					$scope.hasMemberRole = $rootScope.hasMemberRole;

					let historyElements = new DevExpress.data.CustomStore({
						load: function (loadOptions) {
							var parameters = {};
							parameters.skip = loadOptions.skip || 0;
							parameters.take = loadOptions.take || 12;
							var options = { filter: [], sort: {} };

							for (let s in loadOptions.sort) {
								options.sort[loadOptions.sort[s].selector] =
									loadOptions.sort[s].desc;
							}

							createCondition(loadOptions.filter, options.filter, false);

							return ExportHistoryService.getAll(
								parameters.skip,
								parameters.take,
								options
							).then(function (response) {
								const count = Number(response.headers['x-total-count']);
								vm.data.count = count
								return {
									data: formatContent(response.data),
									totalCount: count,
								};
							});
						},
					});

					let dataGridOptions = {
						dataSource: {
							store: historyElements,
						},
						remoteOperations: {
							sorting: true,
							paging: true,
							filtering: true,
						},
						paging: {
							enabled: true,
							pageSize: PAGINATIONS_SIZE,
						},
						scrolling: {
							rowRenderingMode: 'Standard',
							showScrollbar: 'onHover',
						},
						pager: {
							showPageSizeSelector: true,
							allowedPageSizes: PAGINATIONS_SIZES,
							infoText: $rootScope.dxGridTextInfo,
							showInfo: true,
							visible: true,
						},
						searchPanel: {
							visible: true,
							width: 240,
						},
						filterRow: {
							visible: true,
							applyFilter: 'auto',
						},
						rowAlternationEnabled: true,
						headerFilter: {
							visible: true,
							applyFilter: 'auto',
						},
						showColumnLines: true,
						showRowLines: true,
						allowColumnReordering: true,
						allowColumnResizing: true,
						columnAutoWidth: true,
						showBorders: true,
						columnChooser: {
							enabled: true,
						},
						columnFixing: {
							enabled: true,
						},
						columns: [
							{
								caption: dataGirdColumnName.type,
								dataField: 'typeLib',
							},
							{
								caption: dataGirdColumnName.date,
								dataField: 'date',
								dataType: 'datetime',
								format: 'dd/MM/yyyy HH:mm:ss',
								allowSearch: false,
							},
							{
								caption: dataGirdColumnName.labelDatablock,
								dataField: 'dataBlockLib',
							},
							{
								caption: dataGirdColumnName.status,
								alignment: 'center',
								cellTemplate: 'status',
								dataField: 'status',
								allowFiltering: false,
								allowHeaderFiltering: true,
								width: 120,
							},
							{
								caption: dataGirdColumnName.result,
								alignment: 'center',
								cellTemplate: 'result',
							},
							{
								caption: dataGirdColumnName.properties,
								alignment: 'center',
								cellTemplate: 'properties',
								width: 120,
							},
						],
					};

					let formatContent = function (content) {
						let formattedContent = [];
						for (var i in content) {
							formattedContent.push({
								id: content[i].id,
								date: content[i].date,
								typeLib: $scope.typeMap(content[i].type),
								result: content[i].result,
								dataBlockLib: content[i].data_block.metadata.label,
								dataBlockId: content[i].data_block.id,
								status: content[i].status,
								type: content[i].type,
								properties: content[i].data_spark_export_request,
								username: content[i].user_id ? content[i].user_id : '-',
								isLocal: content[i].is_local,
								jobId: content[i].job_id,
							});
						}

						return formattedContent;
					};

					vm.initHistory = function () {
						$scope.dataGridOptions = dataGridOptions;
					};

					let DbType = gettextCatalog.getString(
						'datablock.export.history.database'
					);

					$scope.typeMap = function (type) {
						switch (type) {
							case 'csv':
								return 'CSV';
							case 'db':
								return DbType;
							case 'json':
								return 'JSON';
							default:
								return type;
						}
					};

					$scope.showProperties = function (historyId) {
						ExportHistoryService.getRequestDetailsById(historyId).then(function (
							response
						) {
							$scope.propertiesData = response.data;
							$('#propertiesModal').modal('show');
						});
					};

					$scope.$on('exportJobFinished', function (event, notif) {
						if (notif && notif.data) {
							var foundObject = _.find(
								$scope.exportHistoryGridData,
								function (el) {
									return el.id == notif.data.id;
								}
							);
							if (foundObject != undefined) {
								for (var i in $scope.exportHistoryGridData) {
									if ($scope.exportHistoryGridData[i].id == notif.data.id) {
										var elm = notif.data;
										prepareHistoryElem(elm);
										$scope.exportHistoryGridData[i] = elm;
										$scope.gridInstance.refresh();
										break;
									}
								}
							} else {
								let element = notif.data;
								prepareHistoryElem(element);
								$scope.exportHistoryGridData.unshift(element);
								$scope.gridInstance.refresh();
							}
						}
					});

					function prepareHistoryElem(elm) {
						elm.date = $rootScope.getDateTimeWithPattern(elm.date);
						elm.typeLib = $scope.typeMap(elm.type);
						elm.dataBlockLib = elm.data_block.metadata.label;
						elm.dataBlockId = elm.data_block.id;
					}

					function createCondition(filter, options, exclude) {
						if (filter !== undefined) {
							if (typeof filter[0] === 'string' && typeof filter !== 'string') {
								if (filter[0] === '!') {
									createCondition(filter[1], options, true);
								} else {
									if (filter[2] !== undefined && filter[2] !== null) {
										options.push({
											field: filter[0],
											operator: exclude ? '<>': filter[1],
											value: filter[0] === 'date' ? moment(filter[2]).toISOString(): filter[2],
										});
									} else {
										options.push({
											field: filter[0],
											operator: exclude ? '<>': filter[1],
										});
									}
								}
							} else if (filter[0] instanceof Array) {
								if (filter[1] === 'and') {
									options.push({
										condition: exclude ? 'or': 'and',
										options: [],
									});
								} else if (filter[1] === 'or') {
									options.push({
										condition: exclude ? 'and': 'or',
										options: [],
									});
								}
								for (let i = 0; i < filter.length; i++) {
									createCondition(filter[i], options[options.length-1].options, exclude);
								}
							}
						}
					}

					$scope.widgetData = {};
					$scope.widgetData.showDownloadFiles = false;
					$scope.getFilesToDownLoad = function (history) {
						ExportHistoryService.getFilesListByJobId(history.jobId).then(function (
							response
						) {
							var data = response.data;
							$scope.widgetData.filesToExport = data;
							$scope.widgetData.showDownloadFiles = true;
						});
					};
				},
			];
			return buildDirectiveReturnObject(
				{ showElementHistory: '=', data: '=' },
				controller,
				'./src/components/directives/exporthistory/datablockExportHistory.html',
				function postLink(scope, element, attrs) {
					initElementForDirective(element, attrs, function () {
						scope.vm.showElementHistory = false;
						scope.vm.data = {};
					});
					watchElementForDirective(
						element,
						scope,
						function () {
							return scope.vm.showElementHistory;
						},
						function () {
							scope.vm.initHistory();
						},
						true
					);
				}
			);
		},
	]);
})();
