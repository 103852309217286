(function () {
	'use strict';

	angular.module('dcApp').directive('consumedElementError', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'$state',
				'gettextCatalog',
				'ElementsLinkGenerator',
				function ($scope, $state, gettextCatalog, ElementsLinkGenerator) {
					let deleteReportTra = gettextCatalog.getString(
						'Rapport de suppression'
					);

					$scope.visiblePopup = false;
					$scope.popupOptions = {
						width: 600,
						height: 'auto',
						contentTemplate: 'info',
						showTitle: true,
						position: { my: 'top', at: 'top', of: window, offset: '0 30' },
						title: deleteReportTra,
						dragEnabled: false,
						shading: false,
						hideOnOutsideClick: true,
						bindingOptions: {
							visible: 'visiblePopup',
						},
						onHidden: function () {
							$scope.visiblePopup = false;
							$scope.vm.showErr = false;
						},
					};

					$scope.getTypeLabelFromHdhType = function (type) {
						return gettextCatalog.getString(getTypeLabelFromHdhType(type));
					};

					$scope.showInfo = function () {
						$scope.elements = [];
						$scope.totalRequestElement = $scope.vm.data.total_request_element;
						$scope.totalDeletedElement = $scope.vm.data.total_deleted_element;
						$scope.showDetails = {};
						if ($scope.totalRequestElement > 0) {
							$scope.reports = $scope.vm.data.element_dependencies;
						} else {
							for (let i in $scope.vm.data.element_dependencies) {
								$scope.elementLib = $scope.vm.data.element_dependencies[i].v1;
								$scope.is_exposed = $scope.vm.data.element_dependencies[i].v3;
								for (let j in $scope.vm.data.element_dependencies[i].v2) {
									$scope.elements.push(
										$scope.vm.data.element_dependencies[i].v2[j]
									);
								}
							}
						}
						$scope.visiblePopup = true;
					};

					$scope.getLink = function (el) {
						ElementsLinkGenerator.getLinkAndGo(el);
					};
					// bug wheel scroll inside dx popup fix
					$('#popupConsumedTemplate').on('dxmousewheel', function (e) {
						e.stopPropagation();
					});
				},
			];

			return buildDirectiveReturnObject(
				{ data: '=', showErr: '=' },
				controller,
				'./src/components/directives/consumedElementError/consumedElementError.html',
				function postLink(scope, element, attrs) {
					scope.$watch(
						function () {
							return scope.vm.showErr;
						},
						function (value) {
							if (value) {
								scope.showInfo();
							}
						}
					);
					$(element).on('hidden.bs.modal', function (event) {
						if (event.target.id == element[0].id) {
							scope.vm.showErr = false;
						}
					});
				}
			);
		},
	]);
})();
