(function () {
	'use strict';

	angular.module('dcApp').directive('sankeychartview', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'$state',
				'ChartService',
				'hdSourceService',
				'$window',
				'PermissionService',
				'ChartViewCommonFactory',
				'gettextCatalog',
				'$filter',
				'$timeout',
				'DX_RERENDER_TIMEOUT',
				function (
					$scope,
					$state,
					ChartService,
					hdSourceService,
					$window,
					PermissionService,
					ChartViewCommonFactory,
					gettextCatalog,
					$filter,
					$timeout,
					DX_RERENDER_TIMEOUT
				) {
					var vm = this;

					vm.init = function (ignoreCache) {
						ChartViewCommonFactory.initialize(
							'sankey',
							$scope,
							checkConfig,
							loadGroupDataInChart,
							ignoreCache
						);
					};

					ChartViewCommonFactory.initializeDirectiveScope($scope, vm, 'sankey');

					$scope.showPopover = function ($event, obj) {
						if (Object.keys(obj).length === 0) {
							$scope.noFilterApplied = true;
						} else {
							$scope.noFilterApplied = false;
						}
						// Set a bigger z-index
						$(function () {
							DevExpress.ui.dxOverlay.baseZIndex(1999999998);
						});
					};

					function initPopover() {
						$scope.sourceCentring = vm.data.centering
							? vm.data.centering.source_centering
							: {};
						$scope.filterDetailsPopover = {
							target: '#filterInfoPopover' + $scope.uuid,
							showEvent: 'dxclick',
							position: 'top',
							width: 450,
							bindingOptions: {
								visible: 'visibleMetaData',
							},
						};
					}

					var loadGroupDataInChart = function (data) {
						$scope.config.global.tooltip = {
							enabled: true,
							customizeLinkTooltip: function (info) {
								return {
									html:
										'<b>Source:</b> ' +
										info.source +
										'<br/><b>Cible:</b> ' +
										info.target +
										'<br/>' +
										'<b>Valeur:</b> ' +
										info.weight,
								};
							},
						};
						initPopover();
						if (!$scope.config.global.label) {
							$scope.config.global.label = {};
						}
						$scope.config.global.label.font = {
							size: 12,
							weight: 400,
							color: $scope.config.global.label.fontColor,
						};
						$scope.dxChartOptions = $scope.config.global;
						$scope.dxChartOptions.dataSource = data;

						$('#chart' + $scope.uuid).dxSankey($scope.dxChartOptions);
						$timeout(function () {
							$('#chart' + $scope.uuid)
								.dxSankey('instance')
								.render();
						}, DX_RERENDER_TIMEOUT);

						ChartViewCommonFactory.chartAutoResizing(
							$scope,
							$('#chart' + $scope.uuid).dxSankey('instance'),
							DX_RERENDER_TIMEOUT
						);

						return true;
					};

					var checkConfig = function (config) {
						return true;
					};
				},
			];

			return buildDirectiveReturnObject(
				{ data: '=' },
				controller,
				'./src/components/directives/handledata/chartView/sankey/chartView.html',
				function postLink(scope, element, attrs) {
					if (!scope.vm.data.full_data) {
						scope.jobDetails = { rerunMethod: scope.rerunWithoutCache };
						scope.jobDetailsId = generateUuid('_');
					}
					const ignoreCache = scope.vm.data.ignoreCache || false;
					scope.vm.init(ignoreCache);
				}
			);
		},
	]);
})();
